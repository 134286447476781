@import '../../../../variables.scss';

.body {
    text-align: center;
}

.caption {
    margin-bottom: 10px;
    width: 90%;
    .description {
        font-family: $font-text;
        font-size: medium;
    }
    
    .source {
        text-align: center;
        font-family: $font-text;
        font-style: italic;
        font-size: x-small;
    }    
}