@import "~bootstrap/scss/bootstrap";
@import '../../../variables.scss';

.header {
    margin-bottom: 60px;
}

.title {
    font-family: $font-title;
}

.link {
    font-family: $font-text;
}

.link:hover {
    color: white;
}

