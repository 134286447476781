@import '../../../variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    caption {
        text-align: center;

        .source {
            font-family: $font-text;
            font-style: italic;
            font-size: x-small;
        }  
    }
}