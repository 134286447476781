@import '../../../variables.scss';

h1 {
    font-family: $font-title;
    margin-left: 5%
}

.btn--all {
    width: 100px;
    font-family: $font-text;
    border-radius: 5px;
    margin-top: 2%;
}

.filters {
    display: flex;
    flex-direction: column;
    margin: 5%;
    margin-top: 1%;

    .allBtn {
        @extend .btn--all;
        background-color: #d62828;
        color: white;
        border: 1px #d62828 solid;
        margin-bottom: 2%;
    }

    .allBtn:focus {
        background-color: white;
        color: #d62828;
    }

    .filterLabel {
        font-family: $font-text;
    }

    .countryFilter {
        display: flex;
        flex-wrap: wrap; 
        width: 95%;
        justify-content: space-between;

        .countryBtn {
            @extend .btn--all;
            width: 110px;
            background-color: #003049;
            color: white;
            border: 1px #003049 solid;
            margin-right: 10px;
        }

        .countryBtn:focus {
            background-color: white;
            color: #003049;
        }
    }

    .decadeFilter {
        display: flex;
        flex-wrap: wrap; 
        width: 95%;
        justify-content: space-between;

        .decadeBtn {
            @extend .btn--all;
            background-color: #FF9C33   ;
            color: black;
            border: 1px #FF9C33 solid;
            margin-right: 10px;
        }

        .decadeBtn:focus {
            background-color: black  ;
            color: #FF9C33 ;
        }
    }
}

.instruction {
    font-family: $font-text;
    font-style: italic;
    font-size: 12px;
    margin-left: 5%;
}

.container {
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: center;
    margin-top: 5%;
}

@media (min-width: 700px) {
    .container {
        display: flex;
        flex-direction: row;
        width: 650px;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 50px;
        flex-wrap: wrap;
        ;
    }

    .filters {    
        .countryFilter {
            display: flex;
            flex-wrap: wrap; 
            width: 80%;
            justify-content: flex-start;
        }
    
        .decadeFilter {
            display: flex;
            flex-wrap: wrap; 
            width: 90%;
            justify-content: flex-start;
        }
    }
}

@media (min-width: 1000px) {
    .container {
        display: flex;
        flex-direction: row;
        width: 1000px;
        justify-content: space-evenly;
        align-items: flex-end;
        padding-left: 100px;
        flex-wrap: wrap;
        ;
    }
}

@media (min-width: 1200px) {
    .container {
        display: flex;
        flex-direction: row;
        width: 1200px;
        justify-content: space-evenly;
        align-items: flex-end;
        padding-left: 100px;
        flex-wrap: wrap;
        ;
    }
}
