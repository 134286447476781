@import '../../../variables.scss';

.logo {
    display: flex;
    justify-content: center;
    margin-top: 20%;

    .engNameGroup {
        display: flex;
        flex-direction: column;
        font-size: 40px;
        justify-content: flex-end;

        .lastName {
            font-family: $font-title;
            text-align: center;
            font-size: 50px;
        }

        .firstName {
            margin-top: -10px;
        }
    }
    
    .cnName {
        writing-mode: vertical-lr;
        text-orientation: upright;
        font-size: 40px;
        text-align: center;
    }
}

.date {
    display: flex;
    justify-content: center;
    font-family: $font-date;
    font-size: 20px;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    margin: 5%;

    .caption {
        font-family: $font-text;
        font-style: italic;
        font-size: x-small;   
    }

    .imgSize {
        height: 150px;
        width: 300px;
    }
}

p {
    margin-left: 10%;
    width: 80%;
    text-align: center;
    font-family: $font-text;
}

@media (min-width: 800px) {
    .logo {
        display: flex;
        width: 50%;
        justify-content: center;
        margin-top: 5%;
        margin-left: 25%;
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .imgSize {
            height: 300px;
            width: 600px;
        }
    }
}

@media (min-width: 1200px) {
    .logo {
        display: flex;
        width: 50%;
        justify-content: center;
        margin-top: 5%;
        margin-left: 25%;
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .imgSize {
            height: 500px;
            width: 1000px;
        }
    }
}
