@import '../../../variables.scss';

.footer {
    .title {
        font-family: $font-title;
        font-size: 12px;
        margin-right: 0;
    }
    
    small {
        
        font-family: $font-text;
        font-size: 10px;
        color: white;
    }
    
    .link {
        font-family: $font-text;
        font-size: 12px;
        color: white;
        text-decoration: none;
    }
    
    .link:hover {
        color: white;
    }
}

@media (min-width: 700px) {
    .footer {
        small, .link {
            font-size: 16px;
        }
    }
}

