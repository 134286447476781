@import '../../../variables.scss';

h1 {
    font-family: $font-title;
}

.chronologyContainer {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 2%;
}

.date {
    font-family: $font-date;
    font-size: 18px;
    font-weight: bold;
}

ul {
    padding-left: 0px;

    li {
        display: flex;
        margin-bottom: 2%;
        margin-right: 2%;

        .date {
            font-family: $font-date;
            font-size: 18px;
            font-weight: bold;
        }
        
        .text {
            font-family: $font-text;
            margin-left: 20px;
        }
    }
}